<template>
	<b-row>
		<b-col cols="12">
			<RegisterForm :is-edit="true" :is-dublicate="true" />
		</b-col>
	</b-row>
</template>

<script>
// eslint-disable-next-line import/no-cycle
import router from '@/router';
import { BRow, BCol } from 'bootstrap-vue';
import { mapActions } from 'vuex';
import RegisterForm from './RegisterForm.vue';

export default {
	components: {
		BRow,
		BCol,
		RegisterForm,
	},
	created() {
		const { id, isApproved } = router.currentRoute.params;
		if (isApproved) {
			this.getApprovedProductById(id);
		} else {
			this.getProductById(id);
		}
		this.getProductCategories();
		this.getRegions();
		this.getBranches();
	},
	methods: {
		...mapActions('products', [
			'getProductById',
			'getRegions',
			'getProductCategories',
			'getApprovedProductById',
			'getBranches',
		]),
	},
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
